<template>
  <div class="organ-management-page">
    <el-row :gutter="20">
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="searchFrom" class="demo-form-inline search searchFrom" size="small">
            <el-form-item label="客户姓名:">
              <el-input
                v-model="searchFrom.doctorName"
                :clearable="true"
                placeholder="请输入客户姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="客户电话:">
              <el-input
                v-model="searchFrom.nurseName"
                :clearable="true"
                placeholder="请输入客户电话"
              ></el-input>
            </el-form-item>
            <el-form-item label="预约类别:">
              <el-select v-model="searchFrom.region" clearable placeholder="请选择预约类型" >
                <el-option label="全部 " value=""></el-option>
                  <el-option v-for="(item, index) in appointmentList" :key="index" :label="item.dictionaryName" :value="item.dictionaryCode"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="预约时间:">
              <el-date-picker type="date" clearable @change="changeDate" value-format="yyyy-MM-dd" placeholder="请选择预约时间" v-model="searchFrom.date1" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item label="任务状态:">
              <el-select v-model="searchFrom.type" clearable placeholder="请选择任务状态">
                <el-option label="全部 " value=""></el-option>
                <!-- <el-option label="新单" value="1"></el-option> -->
                <el-option label="待确认预约" value="2"></el-option>
                <el-option label="已确认预约" value="3"></el-option>
                <el-option label="已采样" value="4"></el-option>
                <el-option label="已送检" value="5"></el-option>
                <el-option label="已完成" value="6"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-search" type="primary" :disabled="disabledSearch" @click="searchRes">查询</el-button>
            </el-form-item>
          </el-form>
          <!-- <el-form :inline="true" :model="searchFrom" class="demo-form-inline searchFrom" size="small">
            <el-form-item>
              <el-button plain icon="el-icon-plus" type="primary" @click="addAppointment">添加</el-button>
            </el-form-item>
          </el-form> -->
        </el-card>
      </el-col>
    </el-row>
    <el-card shadow="always" class="card">
      <div>
        <!-- <el-button plain icon="el-icon-plus" type="primary" @click="addAppointment">添加</el-button> -->
        <!-- <el-button plain icon="el-icon-delete" type="danger" @click="handleDelete">删除</el-button> -->
      </div>
      <div class="table-block">
      <el-table :data="tableData" v-loading="loading" :header-cell-style="{'text-align': 'center', 'background': '#EEF1FC', 'color': '#999'}" :cell-style="{'text-align':'center'}" style="width: 100%" @selection-change="handleSelectionChange">
        <!-- <el-table-column fixed type="selection" width="50"></el-table-column> -->
        <el-table-column prop="customName" label="客户姓名"> </el-table-column>
        <el-table-column prop="subStatusName" width="120" label="预约状态"> </el-table-column>
        <el-table-column prop="tel1" width="180" label="手机号"> </el-table-column>
        <el-table-column prop="samplingName" label="预约类型"> </el-table-column>
        <el-table-column prop="samplingNameOn" label="预约对象"> </el-table-column>
        <el-table-column prop="samplingTypeOn" label="预约来源"> </el-table-column>
        <el-table-column prop="taskStartTime1" width="180" label="预约时间"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="240">
          <template slot-scope="scope">
            <el-button @click="lookFor(scope.row, 2)" type="text" size="small">查看</el-button>
            <el-button v-show="scope.row.status < 3 && scope.row.substatus != '2011'" @click="updateNew(scope.row, 2)" type="text" size="small">更新</el-button>
            <el-button @click="addNew(scope.row, 2)" v-show="scope.row.havingReport" type="text" size="small">问卷调查</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
     <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    </el-card>
   
    <addAppointment v-if="showAddAppointment" @closeAddAppointment="closeAddAppointment"></addAppointment>
    <updateAppointment :updateNew123="updateNew123" :info="info" @colseUpdateAppointment="colseUpdateAppointment" v-if="showUpdateAppointment" :type="true"></updateAppointment>
    <questionnaire @closeQuestion="closeQuestion" :info="info" v-if="isShowQuestion"></questionnaire>
  </div>
</template>

<script>
import addAppointment from '@/views/appointmentManagement/components/addAppointment'
import updateAppointment from '@/views/appointmentManagement/components/updateAppointment'
import questionnaire from '@/views/appointmentManagement/components/questionnaire'
import { postRequest } from '@/api'
import { formatTime } from '@/utils/utils'
export default {
  data () {
    return {
      searchFrom: {
        doctorName: '',
        nurseName: '',
        date1: '',
        region: '',
        type: ''
      },
      tableData: [],
      multipleSelection: [],
      showAddAppointment: false,
      showUpdateAppointment: false,
      pageNo: 1,
      pageSize: 10,
      disabledSearch: false,
      info: '',
      updateNew123: '',
      total: null,
      appointmentList:[],
      loading: true,
      isShowQuestion: false,
      info: ''
    }
  },
  mounted(){
    postRequest('/dict/getDictByCategory/', {dictionaryCategoryCode: '07'}).then(res=>{
      console.log(res, '预约类型')
      this.appointmentList = res
    })
    this.getAllOrderInfo()
  },
  components: { addAppointment, updateAppointment,questionnaire },
  methods: {
    addNew (val) {
      console.log(val)
      
      let data = {
        userId: val.userId,
        customId: val.customId,
        orderId: val.orderId
      }
      // let data = {
      //   userId: 29,
      //   customId: 64,
      //   orderId: 690
      // }
      postRequest('/samplingComputer/queryquescustomer', data).then(res => {
        console.log(res, '调查报告')
        this.isShowQuestion = true
        this.info = res
      }).catch(()=>{
        this.isShowQuestion = false
      })
    },
    closeQuestion(){
      this.isShowQuestion = false
    },
    handleSelectionChange (val) {
      console.log(val)
      this.multipleSelection = val
    },
    changeDate(val){
      console.log(val)
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.getAllOrderInfo()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNo = val
      this.getAllOrderInfo()
    },
    closeAddAppointment() {
      this.showAddAppointment = false
      this.getAllOrderInfo()
    },
    addAppointment () {
      this.showAddAppointment = true
    },
    colseUpdateAppointment(){
      this.showUpdateAppointment = false
      this.getAllOrderInfo()
    },
    lookFor(val){
      this.info = val
      this.showUpdateAppointment = true
      this.updateNew123 = false
    },
    updateNew(val,index){
      console.log(val)
      this.info = val
      this.showUpdateAppointment = true
      this.updateNew123 = true
    },
    // clickRow(val){
    //   console.log(val)
    // },
    getAllOrderInfo(){
      this.loading = true
      this.disabledSearch = true
      let data = {
        customerName: this.searchFrom.doctorName ? this.searchFrom.doctorName : '',
        customerTel: this.searchFrom.nurseName ? this.searchFrom.nurseName : '',
        tasktypeCode: this.searchFrom.region ? this.searchFrom.region : '',
        status: this.searchFrom.type ? [this.searchFrom.type] : [],
        samplTime: this.searchFrom.date1 ? this.searchFrom.date1 : '',
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        isSampling: '1'
      }
      postRequest('/samplingComputer/querySamplingInfoPageList', data).then(res=>{
        this.disabledSearch = false
        console.log(res, '查询所有预约信息')
        this.total = res.count
        // res.data.map(item=>{
        //   if (item.tel) {
        //     item.phone = item.tel.replace(reg,"1$1****$2")
        //   }
        // })

        this.tableData = res.data
        this.loading = false
      }).catch(()=>{
        this.loading = false
        this.disabledSearch = false
      })
    },
    searchRes(){
      this.getAllOrderInfo()
    },
    handleDelete () {
    this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        let ids = []
        this.multipleSelection.map(item => {
          ids.push(item.id)
        })
        postRequest('/samplingComputer/deleteSampling', {ids}).then(res=>{
          console.log(res, '删除')
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.getAllOrderInfo()
        })
        
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: '已取消删除'
        });
      });
    }
  }
}
</script>

<style lang="scss" scoped>
// .search > .el-form-item {
//   margin-right: 4px !important; 
// }
.organ-management-page {
  background: transparent;
}
</style>
