<template>
  <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" width="70%" :show-close="false" @close="handleClose">
    <div class="main" v-loading="loading">
      <el-card class="left">
        <el-form label-width="80px" label-position="left">
          <div class="header">
            <div>
              <img class="pic" :src="resule.customPicUrl ? resule.customPicUrl : 'https://dev-uat-alanber.oss-cn-chengdu.aliyuncs.com/4FE9D265B1EF406FA50B208FE59A6A34.png'" alt="">
            </div>
            <div>
              {{resule.customName}}
            </div>
            <div>
              会员号: {{resule.vipCode}}
            </div>
          </div>
          <el-form-item label="手机号:">{{showTelFlag?resule.tel:resule.tel1}} <i class="el-icon-view" style="margin-left:20px" @click="showTel"></i></el-form-item>
          <el-form-item label="机构:">{{resule.companyName}}</el-form-item>
          <el-form-item label="预约类型:">{{resule.samplingName}}</el-form-item>
          <el-form-item label="产品类型:">{{resule.taskName}}</el-form-item>
          <el-form-item label="预约时间:">{{resule.samplingTime}}</el-form-item>
          <el-form-item label="医生:">{{ resule.tasktypeCode == '071' || resule.tasktypeCode == '076' ? resule.docterName : ''}}</el-form-item>
          <el-form-item label="营养师:">{{resule.dietitianName}}</el-form-item>
          <el-form-item label="运动师:">{{resule.fitcouchName}}</el-form-item>
          <el-form-item label="护士:">{{resule.nurseName}}</el-form-item>
          <!-- <el-form-item label="采样方式:">{{resule.tasktypeName}}</el-form-item> -->
        </el-form>
      </el-card>
      <div class="right">
        <el-card class="card-1">
          <el-form ref="form" :model="formation" label-width="80px">
            <el-form-item label="进度更新">
              <el-select @change="change" :disabled="!updateNew123" v-model="formation.status" placeholder="请选择进度">
                <el-option v-for="(item, index) in progressList" :key="index" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>
        <el-card class="progresss">
          <p class="title">历史进度:</p>
          <div class="outBox" v-for="(item, index) in resule.samplingTaskHistoryVos" :key="index">
            <div class="line"></div>
            <div class="word">
              <div class="outPoint" :style="outerPoint(index)">
                <div class="point" :style="point(index)"></div>
              </div>&nbsp;&nbsp;
              <span class="time">{{item.time}}</span>&nbsp;&nbsp;
              <span>{{item.processName}}</span>
            </div>
            <div class="line"></div>
          </div>
        </el-card>
      </div>
    </div>
    <div class="footer">
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureCommit">确 定</el-button>
      </span>
    </div>
  </el-dialog>
</template>

<script>
import { postRequest } from '@/api'
export default {
  data() {
    return {
      dialogVisible: true,
      loading: true,
      showTelFlag: false,
      formation: {
        status: '',
      },
      resule: {},
      progressList: [
        {
          name: '新单',
          value: '1',
        },
        {
          name: '待确认预约',
          value: '2',
        },
        {
          name: '已确认预约',
          value: '3',
        },
        {
          name: '已采样',
          value: '4',
        },
        {
          name: '已送检',
          value: '5',
        },
        {
          name: '已完成',
          value: '6',
        },
        {
          name: '已出报告',
          value: '7',
        },
        {
          name: '已出方案',
          value: '8',
        },
      ],
      outerPoints: [
        'border: 1px solid #A9D900',
        'border: 1px solid #5764F1',
        'border: 1px solid #FF8F00',
        'border: 1px solid #FF8B00',
        'border: 1px solid #FF0000',
        'border: 1px solid pink',
        'border: 1px solid #A9D900',
        'border: 1px solid #5764F1',
        'border: 1px solid #FF8F00',
        'border: 1px solid #FF8B00',
        'border: 1px solid #FF0000',
        'border: 1px solid pink',
      ],
      points: [
        'background: #A9D900;',
        'background:  #5764F1;',
        'background: #FF8F00;',
        'background:  #FF8B00;',
        'background: #FF0000;',
        'background: pink;',
        'background: #A9D900;',
        'background:  #5764F1;',
        'background: #FF8F00;',
        'background:  #FF8B00;',
        'background: #FF0000;',
        'background: pink;',
      ],
    }
  },
  props: ['info', 'updateNew123','type'],
  mounted() {
    if (this.info) {
      postRequest('/samplingComputer/querySamplingInfo', { id: this.info.id })
        .then(res => {
          console.log(res, '信息')
          this.loading = false
          // res[0].samplingTaskHistoryVos = res[0].samplingTaskHistoryVos.slice(-4)
          res[0].samplingTaskHistoryVos = res[0].samplingTaskHistoryVos.reverse()
          this.resule = res[0]
          console.log(666666,this.type)
          if (this.resule.tasktypeCode == '076' || this.type) {
            this.progressList = [
              {
                name: '新单',
                value: '1',
              },
              {
                name: '待确认预约',
                value: '2',
              },
              {
                name: '已确认预约',
                value: '3',
              },
            ]
          } else {
            this.progressList = [
              {
                name: '新单',
                value: '1',
              },
              {
                name: '待确认预约',
                value: '2',
              },
              {
                name: '已确认预约',
                value: '3',
              },
              {
                name: '已采样',
                value: '4',
              },
              {
                name: '已送检',
                value: '5',
              },
              {
                name: '已完成',
                value: '6',
              },
              {
                name: '已出报告',
                value: '7',
              },
              {
                name: '已出方案',
                value: '8',
              },
            ]
          }
          this.progressList.map((item, index) => {
            if (this.resule.status == item.value) {
              this.progressList = this.progressList.slice(index)
            }
          })

          this.formation.status = this.resule.status.toString()
        })
        .catch(() => {
          this.loading = false
        })
    }
  },
  methods: {
    showTel() {
      this.showTelFlag = this.showTelFlag ? false : true
      let e = document.getElementsByClassName('el-icon-view')[0]
      this.showTelFlag ? e.setAttribute('style', 'color: #409EFF;margin-left:20px') : e.setAttribute('style', 'color: #c0c4cc;margin-left:20px')
    },
    handleClose() {
      this.$emit('colseUpdateAppointment')
    },
    change(val) {
      console.log(val)
      this.$forceUpdate()
    },
    outerPoint(i) {
      return this.outerPoints[i]
    },
    point(i) {
      return this.points[i]
    },
    sureCommit() {
      if (this.updateNew123 == false) {
        this.handleClose()
      } else {
        let data = {
          id: this.resule.id,
          status: this.formation.status,
          samplingType: this.resule.samplingType,
        }
        postRequest('/samplingComputer/updateSampling', data).then(res => {
          console.log(res)
          this.$message({
            message: '修改成功',
            type: 'success',
          })
          this.handleClose()
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  margin-top: -30px;
  .left {
    width: 37%;
    margin-right: 20px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 5px 5px 5px #999;
    .el-form-item {
      margin-bottom: 0px !important;
      color: #676767;
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .card-1 {
      margin-bottom: 20px;
      .el-form {
        background: #fff;
        border-radius: 5px;
      }
    }
    .progresss {
      display: flex;
      background: #fff;
      padding: 0 20px;
      height: 100%;
      border-radius: 5px;
      .el-steps {
        margin-top: 40px;
      }
    }
    span {
      align-self: flex-end;
    }
    .el-select {
      width: 100%;
    }
  }
}
.pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.title {
  font-size: 14px;
  color: #444;
  text-align: left;
  margin-bottom: 16px;
}
.header {
  text-align: center;
  img {
    width: 90px;
    height: 90px;
    margin-bottom: 29px;
  }
  :nth-child(2) {
    margin-bottom: 13px;
    font-size: 16px;
    color: #323232;
    font-weight: 400;
  }
  :nth-child(3) {
    font-size: 14px;
    padding-bottom: 23px;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 23px;
    color: #676767;
  }
}
.dialog-footer {
  margin-top: 20px;
}
.footer {
  padding-top: 30px;
  text-align: center;
}
.el-dialog__headerbtn {
  display: none !important;
}
.outBox {
  margin: 0 auto;
  margin-left: 80px;
}
.word {
  display: flex;
  align-items: center;
  margin: 0 auto;
  .outPoint {
    border: 1px solid;
    border-radius: 50%;
    .point {
      margin: 2px;
      width: 4px;
      height: 4px;
      background: #999;
      border-radius: 50%;
    }
  }
  .time {
    color: #999;
  }
}
.line {
  width: 1px;
  height: 20px;
  background: #e6e6e6;
  margin: -5px 4px -5px;
}
.el-icon-view:hover {
  cursor: pointer;
}
</style>